<template>
  <category-risk
    category="cloud"
    title="Cloud Security"
    :banner="banner"
  />
</template>

<script>
  export default {
    name: 'DashboardCloud',

    data: () => ({
      banner: {
        title: 'What is Cloud Security?',
        body: "Cloud security is the protection of data stored online via cloud computing platforms from theft, leakage, and deletion. Methods of providing cloud security include firewalls, penetration testing, obfuscation, tokenization, virtual private networks (VPN), and avoiding public internet connections."
      }
    }),

    components: {
      CategoryRisk: () => import('../component/Category'),
    }
  }
</script>
